<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            v-if="langInfo"
            :src="$i18n.locale == 'fa' ? fa_auth_banner : en_auth_banner"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 px-lg-5 pt-lg-2"
      >

        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <AlertForDeprecate full/>
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t("Pages.Auth.Title.Registration") }}
          </b-card-title>
          <b-card-text class="mb-2">
            <b-alert
              show
              dismissible
              fade
              class="mb-0"
              variant="success"
              v-if="sendEmail == true"
            >
              <div class="alert-body" v-if="sendEmail">
                <span>{{ $t("Pages.Auth.Title.success_email") }}</span>
              </div>
            </b-alert>
            {{ $t("Pages.Auth.Title.enter_info") }}
          </b-card-text>

          <!-- form -->
          <b-form class="auth-register-form mt-2">
            <!-- username -->
            <b-form-group
              :label="$t('Pages.Auth.Label.userName_label')"
              label-for="fullname"
            >
              <b-form-input
                id="fullname"
                v-model="fullname"
                name="fullname"
                placeholder=""
              />
            </b-form-group>

            <!-- email -->
            <b-form-group
              :label="$t('Pages.Auth.Label.register_email_label')"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userEmail"
                name="email"
                placeholder=""
              />
            </b-form-group>

            <!-- password -->
            <b-form-group
              label-for="password"
              :label="$t('Pages.Auth.Label.password_label')"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="password"
                  v-model="password"
                  class="form-control-merge"
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'"
                    @click="togglePassword"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- ///////conditions///////////// -->
            <b-form-group>
              <b-form-checkbox
                id="terms-conditions"
                v-model="acceptTerms"
                name="checkbox-1"
              >
                <b-link
                class="text_small"
                  target="blank"
                  href="https://master.tube/terms-of-service/"
                  >{{ $t("Pages.Auth.Title.accept_rules") }}</b-link
                >
              </b-form-checkbox>
            </b-form-group>
            <!-- ///////privacy-policy///////////// -->
            <b-form-group>
              <b-form-checkbox
                id="privacy-policy"
                v-model="acceptPolicy"
                name="checkbox-2"
              >
              <b-link
              class="text_small"
                  target="blank"
                  href="https://master.tube/privacy-policy/"
                  >  {{
                  $t("Pages.Auth.Title.accept_policy")
                }}</b-link
                >

              </b-form-checkbox>
            </b-form-group>

            <b-button
              variant="primary"
              block
              type="submit"
              @click.prevent="signup"
            >
              {{ $t("Pages.Auth.Button.Register") }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>{{ $t("Pages.Auth.Title.already_have_account") }} </span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;{{ $t("Pages.Auth.Title.Login") }} </span>
            </b-link>
          </p>

          <b-card-text class="text-center mt-2">
            <span>{{ $t("Pages.Auth.Title.forget_pass") }} </span>
            <b-link :to="{ name: 'forgetPassword' }">
              <span>&nbsp;{{ $t("Pages.Auth.Title.recovery_pass") }}</span>
            </b-link>
          </b-card-text>

          <div class="auth-footer-btn d-flex justify-content-center">
            <div class="google-btn" v-on:click="loginGoogle()">
              <div class="google-icon-wrapper">
                <img
                  class="google-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                />
              </div>
              <p class="btn-text text-center">
                <b>{{ $t("Pages.Auth.Button.google_login") }}</b>
              </p>
            </div>
          </div>

          <!-- <b-card-text class="mb-0 text-center">
            <b-img
              :src="
                $store.state.appConfig.layout.skin == 'dark'
                  ? require('@/assets/images/banner/ytc-small-light.png')
                  : require('@/assets/images/banner/ytc-small.png')
              "
              alt="logo"
              height="80"
            />
          </b-card-text> -->
          <!-- /////change lang section -->
          <b-card-text class="mb-0 text-center">
            <Locale />
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BAlert,
} from "bootstrap-vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import { langInfoDao } from "@/daos/languages/langInfoDao";
import AlertForDeprecate from "@/components/AlertForDeprecate.vue";

export default {
  components: {
      AlertForDeprecate,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    Locale,
  },
  data() {
    return {
      showPassword: false,
      acceptTerms: true,
      acceptPolicy: true,
      fullname: "",
      userEmail: "",
      password: "",
      langInfo: null,
      en_auth_banner: null,
      fa_auth_banner: null,
      sendEmail: false,
    };
  },
  async created() {
    this.getAllBanners();
    try {
      const resp = await this.$http.get("/api/check_login");
      const status = resp.data.status;
      if (status !== 401) {
        const locale = resp.data.user.selected_language_key;
        localStorage.setItem("language", locale);
        const isRTL = locale === "fa";
        document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
        this.$i18n.locale = locale;
        this.$router.push("/").catch(() => {});
      }
    } catch (err) {
      // if user not login......
      this.$VueCookies.remove("mastertube");
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    loginGoogle() {
      this.makeToast(
        this.$t("Alert.Successful"),
        this.$t("Pages.Auth.Alert.Please Wait"),
        "success"
      );
      this.$http.get(`/api/login/google`).then((response) => {
        window.location.href = response.data;
      });
    },
    signup() {
      if (this.userEmail != "" && this.password != "" && this.fullname != "") {
        if (
          this.userEmail.includes("gmail.com") ||
          this.userEmail.includes("yahoo.com")
        ) {
          let referrer_id = "";
          if (localStorage.getItem("mastertube_referral")) {
            referrer_id = localStorage.getItem("mastertube_referral");
          }
          const payload = {
            email: this.userEmail,
            password: this.password,
            name: this.fullname,
            referrer_id: referrer_id,
            language_key: this.$i18n.locale,
          };
          this.$http.post(`/api/register`, payload).then((resp) => {
            if (resp.status == 200) {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Base.Alert.success_regis_complet"),
                "success"
              );
              this.sendEmail = true;
              if (this.$route.query.redirect) {
                this.$router.push({ name: this.$route.query.redirect });
              }
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.email_already_taken"),
                "danger"
              );
              this.$VueCookies.remove("mastertube");
            }
          });
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.use_gmail_or_yahoo"),
            "danger"
          );
        }
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Base.Alert.info_incomplete"),
          "danger"
        );
      }
    },
    async getAllBanners() {
      const langInfo= await langInfoDao.getLangInfo()
      this.langInfo=langInfo;
      const en_banners = langInfo.en.images;
      const fa_banners = langInfo.fa.images;
      this.en_auth_banner = en_banners.auth_banner;
      this.fa_auth_banner = fa_banners.auth_banner;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.text_small{
  font-size: 12px;
}
</style>
